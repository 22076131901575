import React from "react";

export const Header = (props) => {
  return (
    <div id="header" className="text-center">
          <div className="container">

          <div className="intro">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p class="fw-bold">{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="#about"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Узнать больше
                </a>{" "}
             
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};
